import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Accordion from 'react-bootstrap/Accordion'

const ActivitiesPage = () => {
  return (
    <Layout activePage="activities">
      <Seo title="Адвокатский кабинет Храпова Юрия Николаевича"/>
      <h2 className="main-title">
        Направление деятельности
      </h2>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Услуги гражданам
          </Accordion.Header>
          <Accordion.Body>
            <p className="p-style3"><span className="no-margin text-decoration-underline">Устное и письменное консультирование по вопросам:</span></p>
            <p className="p-style3">гражданского права, семейного права, жилищного права;</p>
            <p className="p-style3">недвижимого имущества;</p>
            <p className="p-style3">по наследству;</p>
            <p className="p-style3">взаимоотношения с органами власти и местного самоуправления, организациями;</p>
            <p className="p-style3">уголовного права;</p>
            <p className="p-style3">административных правонарушений.</p>
            <p className="p-style3">&nbsp;</p>
            <p className="p-style3">&nbsp;</p>
            <p className="p-style3"><span className="no-margin text-decoration-underline">Подготовка документов</span></p>
            <p className="p-style3">Составление договоров купли-продажи, дарения, аренды, в т.ч. по недвижимости,</p>
            <p className="p-style3">договора найма (аренды) жилья или безвозмездного пользования,</p>
            <p className="p-style3">договоров займа с распиской в получении денег,</p>
            <p className="p-style3">соглашений о разделе имущества (между супругами или наследниками),</p>
            <p className="p-style3">соглашений по семейным вопросам,</p>
            <p className="p-style3">иных видов договоров и сделок.</p>
            <p className="p-style3">Составление заявлений, ходатайств, претензий и т.п.</p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Составление исковых заявлений (жалоб) в суд</span><span className="p-style2">,</span></p>
            <p className="p-style3">отзывов, мировых соглашений, апелляционных и кассационных жалоб,</p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Судебная практика&nbsp; - основная специализация:</span></p>
            <p className="p-style3"><span className="p-style2">Споры с органами власти, местного самоуправления, административными органами и организациями.</span></p>
            <p className="p-style3"><span className="p-style2">Семейные споры и взаимоотношения</span></p>
            <p className="p-style3">(раздел имущества между супругами, об определении места проживания ребёнка с одним из родителей, о порядке участия в воспитании ребёнка родителем проживающем отдельно)</p>
            <p className="p-style3"><span className="p-style2">Наследственные дела, ДТП, взыскание ущерба, задолженности.</span></p>
            <p className="p-style3"><span className="p-style2">Споры по жилищным вопросам, споры связанные с объектами недвижимости.</span></p>
            <p className="p-style3"><span className="p-style2">Административные дела.</span></p>
            <p className="p-style3">Защита по уголовному делу.</p>
            <p className="p-style3"><span className="p-style2">«Необычные дела» требующие особого юридического подхода,</span></p>
            <p className="p-style3">в плане теоретической и практической подготовки.</p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Представление интересов граждан</span></p>
            <p className="p-style3">в гражданском, уголовном, административном судопроизводстве,</p>
            <p className="p-style3">органах власти и местного самоуправления, организациях.</p>
            <p className="p-style3">Участие в переговорах.</p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            Работа с организациями и предпринимателями
          </Accordion.Header>
          <Accordion.Body>
            <p className="p-style3">Судебная&nbsp; практика в Арбитражных судах России с 1997года.</p>
            <p className="p-style3"><strong className="no-margin">Абсолютно высокая гарантия разрешения дела в Вашу пользу</strong></p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Спектр положительных решений:</span></p>
            <p className="p-style3">от стоимости нескольких мешков сахара (периода бартерных сделок 90-х) до поставок нефти суммарной стоимостью, в сопоставимых ценах 2013г. – 4 миллиарда рублей.<br className="no-margin"/><br className="no-margin"/><span className="no-margin text-decoration-underline">Основная&nbsp; специализация:</span></p>
            <p className="p-style3">Поставка, строительство, подряд, арендные правоотношения, споры по объектам недвижимости, дела связанные с «юридической судьбой» организаций.</p>
            <p className="p-style3"><span className="p-style2">Обычные споры организаций и предпринимателей возникающие в деловом обороте.</span></p>
            <p className="p-style3"><span className="no-margin text-decoration-underline">Отдельную категорию дел</span>&nbsp;составляет успешное обжалование действий администраций, органов власти и местного самоуправления, административных органов (ФАС, МЧС, Роспотребнадзор и т.п.).</p>
            <p className="p-style3"><span className="p-style2">Участие в делах возбуждаемых антимонопольным органом (ФАС).</span></p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Уголовные дела:</span><span className="p-style2">&nbsp;защита предпринимателей и руководителей от уголовного преследования, защита от «давления на бизнес».</span></p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Административные дела</span><span className="p-style2">&nbsp;любых категорий.</span></p>
            <p className="p-style3"><span className="p-style2">Споры с работниками.</span></p>
            <p className="p-style3"><span className="p-style2">Составление правовых заключений, исковых заявлений и жалоб, отзывов, претензий.</span></p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Составление документов</span><span className="p-style2">,</span><strong className="p-style2">&nbsp;</strong><span className="p-style2">договоров, сделок, учредительных документов и документов внутреннего делопроизводства, трудовых контрактов, помощь в деловой переписке, взаимоотношения с органами власти.</span></p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Представление интересов</span></p>
            <p className="p-style3">в федеральных судах, мировом суде, органах власти и местного самоуправления. административных органах, во взаимоотношениях с иными организациями, предпринимателями и гражданами.</p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Доступность сотрудничества</span></p>
            <p className="p-style3">(по договорённости, работа в выходные и праздничные дни, вечернее время,</p>
            <p className="p-style3">срочный выезд в пределах Московской области).</p>
            <p className="p-style3"><span className="p-style2">Наличный&nbsp; и безналичный расчёт.</span></p>
            <p className="p-style3">Скидки по стоимости услуг при длительном сотрудничестве.</p>
            <p className="p-style3"><span className="p-style2 text-decoration-underline">Выезд&nbsp; в регионы.</span></p>
            <p className="p-style3"><span className="p-style2">Гарантия конфиденциальности и доверия.</span></p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Layout>
  )
}

export default ActivitiesPage
